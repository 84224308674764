import React from "react";
import { StaticImage } from "gatsby-plugin-image"


const AgentInfoSidebar = ({ agent, isCoList }) => {
  
  return (
    <div className={`cha-pd-container__about__inner__right__agent ${agent ? '' : 'cha-pd-container__hidden'}`}>
      { agent && (
        <>
          { !isCoList && <p className="subhead2">Contact The Listing Agent</p> }

          {agent.photo ? (
            <img
              alt={agent.fullName}
              src={agent.photo.url}
            />
          ) : agent.Media && JSON.parse(agent.Media).length > 0 ? (
            <img
              alt={agent.fullName}
              src={JSON.parse(agent.Media)[0].Uri}
            />
          ) : (
            <StaticImage
              className="object-cover h-full"
              alt="agent"
              src="../images/no_agent_placeholder.png"
            />
          )}

          <div className="cha-pd-container__about__inner__right__agent__contact">
            <div className="cha-pd-container__about__inner__right__agent__contact__first-text subhead2">
              {agent.fullName}
            </div>
            {agent.mobilePhone && (
              <div className="cha-pd-container__about__inner__right__agent__contact__second-text">
              {agent.mobilePhone}
              </div>
            )}
            <div className="cha-pd-container__about__inner__right__agent__contact__third-text">
              {agent.email}
            </div>
          </div>
        </>
      )
    }
    </div>
  )
}

export default AgentInfoSidebar
