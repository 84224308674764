import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo";
import SimpleMap from "../components/map"
import { Carousel } from "react-responsive-carousel"
import ImageGallery from "react-image-gallery"
import PropertyHeaderDetail from "../components/property-header-detail"
import AboutProperty from "../components/about-property"
import Details from "../components/details";
import VirtualTour from "../components/virtual-tour";
import Gallery from "../components/gallery"

const ResidentialListing = ({ data, location }) => {
  const post = data.listing

  const center = {
    lat: post ? post.Latitude : null,
    lng: post ? post.Longitude : null,
  }
  let galleryImages = []

  if (post && post.Media) {
    galleryImages = post.Media.map(item => ({
      original: item.MediaURL.replace('WIDTH', 1200).replace('HEIGHT', 1200 / 1.5),
      thumbnail: item.MediaURL.replace('WIDTH', 150).replace('HEIGHT', 150 / 1.5),
    }))
  }

  return (
    <Layout>
      <>
        <Seo title={post.UnparsedAddress} location={location} />
        {post && (
          <div className="cha-pd-container">
            <PropertyHeaderDetail property={post} />
            <Carousel
              showArrows={true}
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              autoPlay={true}
              stopOnHover={false}
              interval={50000}
              transitionTime={500}
              preventMovementUntilSwipeScrollTolerance={true}
              swipeScrollTolerance={100}
            >
              {post.Media && post.Media.length
                ? post.Media.map(item => {
                  const media = item

                  return (
                    <img
                      className="carousel-img"
                      src={media.MediaURL.replace("WIDTH", 1200).replace(
                        "HEIGHT",
                        1200 / 1.5
                      )}
                    />
                  )
                })
                : null}
            </Carousel>
            <AboutProperty
              property={post}
              agent={data.listAgent}
              coListAgent={data.coListAgent}
              contactReference={"exclusive_listing"}
            />
            {post.Media.length > 0 ? (
              <Gallery galleryImage={galleryImages} />
            ) : null}
            <div className="sub-container">
              <VirtualTour
                videoUrl={post.VideoUrl}
                exclusive={true}
                tourUrl={post.VirtualTourURLUnbranded}
                unparsedAddress={post.UnparsedAddress}
              />
            </div>
            <Details property={post} exclusive={true} />
            <div className="location-container">
              <h1 className="h1-header">Location</h1>
              <div className="map-container">
                <SimpleMap
                  zoom={14}
                  center={center}
                  address={post.StreetNumber + " " + post.StreetName}
                ></SimpleMap>
              </div>
            </div>
          </div>
        )}
      </>
    </Layout>
  )
}

export const query = graphql`
  query($mlsId: String!, $ListAgentMlsId: String, $CoListAgentMlsId: String) {
    listing(ListingId: { eq: $mlsId }) {
      ListingId
      VirtualTourURLUnbranded
      ListAgentMlsId
      UnparsedAddress
      BathroomsTotalInteger
      BedroomsTotal
      City
      ListOfficeMlsId
      ListPrice
      LivingArea
      PrefferedPhoto
      PropertyType
      Furnished
      FireplaceYN
      CoolingYN
      Media {
        MediaURL
      }
      Latitude
      Longitude
      StreetNumber
      StreetName
      PublicRemarks
      ArchitecturalStyle
      AssociationAmenities
      AttachedGarageYN
      BathroomsFull
      BathroomsHalf
      BathroomsThreeQuarter
      BuildingAreaTotal
      Cooling
      CountyOrParish
      FireplaceFeatures
      FireplacesTotal
      Heating
      HeatingYN
      LaundryFeatures
      LotSizeArea
      LotSizeSquareFeet
      LotSizeUnits
      MLSAreaMajor
      MLSAreaMinor
      ParkingFeatures
      PostalCode
      PropertyCondition
      Roof
      RoomsTotal
      StateOrProvince
      YearBuilt
      StandardStatus
      VideoUrl
      BathroomsOneQuarter
      UnitNumber
      Levels
      ZoningDescription
      LotSizeAcres
      ConstructionMaterials
      ExteriorFeatures
      Utilities
      WaterSource
      View
      OtherEquipment
      PoolFeatures
      Appliances
      InteriorFeatures
      Flooring
      CommunityFeatures
      BuildingFeatures
      LotFeatures
      GarageSpaces
      TaxYear
      TaxAnnualAmount
      AssociationName
      AssociationFee
      AssociationFeeFrequency
      AssociationFeeIncludes
      AssociationAmenities
    }

    listAgent: agent(MemberMlsId: {eq: $ListAgentMlsId}) {
      email
      fullName
      officePhone
      mobilePhone
      photo {
        url
      }
      lastName
      firstName
    }

    coListAgent: agent(MemberMlsId: {eq: $CoListAgentMlsId}) {
      email
      fullName
      officePhone
      mobilePhone
      photo {
        url
      }
      lastName
      firstName
    }
  }
`

export default ResidentialListing
