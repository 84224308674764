import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { FaCircleNotch } from "react-icons/fa"
import axios from "axios"
import ContactSchema from "../helpers/contact-schema"
import { Formik, Form, Field, ErrorMessage } from "formik"
import AgentInfoSidebar from "./agent-info-sidebar"

const AboutProperty = ({
  property,
  agent,
  coListAgent,
  contactReference,
  courtesyOf,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [response, setResponse] = useState("")
  const [creatingLeadAjax, setCreatingLeadAjax] = useState(false)

  return (
    <div className="cha-pd-container__about">
      <div className="cha-pd-container__about__inner">
        <div className="cha-pd-container__about__inner__left">
          <h1 className="h1-header">About This Property</h1>
          <p className="subhead">{`MLS ID ${property.ListingId}`}</p>
          {property.StandardStatus === "Closed" && (
            <p className="subhead">This Property Is No Longer On The Market</p>
          )}
          <p className="body1">{property.PublicRemarks}</p>
          {courtesyOf && <p className="courtesyOf">{courtesyOf}</p>}
        </div>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            message: "",
          }}
          validationSchema={ContactSchema}
          onSubmit={async values => {
            setCreatingLeadAjax(true)

            if (!executeRecaptcha) {
              setResponse("Recaptcha Issue, Try Again Later...")
              setCreatingLeadAjax(false)

              return
            }

            try {
              const recaptchaToken = await executeRecaptcha(contactReference)
              await axios({
                url: process.env.FOUNDATION_LEAD_CREATE_ENDPOINT,
                method: "POST",
                data: {
                  recaptchaToken,
                  formValues: values,
                  propertyInfo: {
                    listingId: property.ListingId,
                    listingAddress: property.UnparsedAddress,
                    listingPrice: property.ListPrice,
                    listingAgentMlsId: property.ListAgentMlsId,
                    listingType: property.PropertyType,
                    createdFrom: "Summit Colorado Christies - Property Page",
                  },
                },
              })

              setResponse(
                `Your contact was sent to us, we'll get in touch briefly with you!`
              )
            } catch (error) {
              setResponse(
                `Sorry, there was an issue sending your contact request...`
              )
            }

            setCreatingLeadAjax(false)
          }}
        >
          {({ handleSubmit }) => (
            <Form
              onSubmit={handleSubmit}
              className="cha-pd-container__about__inner__right"
              name="contact exclusive"
              method="POST"
            >
              <p className="subhead">INQUIRE ABOUT PROPERTY</p>
              <div className="cha-pd-container__about__inner__right__form">
                <input
                  type="hidden"
                  name="form-name"
                  value="contact exclusive"
                />

                <Field name="firstName" placeholder="FIRST NAME" />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  render={msg => <div className="error">*</div>}
                />
                <Field name="lastName" placeholder="LAST NAME" />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  render={msg => <div className="error">*</div>}
                />
                <Field name="email" placeholder="EMAIL ADDRESS" />
                <ErrorMessage
                  name="email"
                  component="div"
                  render={msg => <div className="error">*</div>}
                />
                <Field name="phone" placeholder="PHONE NUMBER" />
                <ErrorMessage
                  name="phone"
                  component="div"
                  render={msg => <div className="error">*</div>}
                />
              </div>
              <AgentInfoSidebar agent={agent} />
              <AgentInfoSidebar agent={coListAgent} isCoList={true} />
              <button
                aria-disabled={creatingLeadAjax}
                disabled={creatingLeadAjax}
                className="cha-pd-container__about__inner__right__button button2"
                type="submit"
              >
                {creatingLeadAjax ? (
                  <FaCircleNotch className="cha-cl__spin-icon" />
                ) : (
                  "SUBMIT"
                )}
              </button>
              {response && <span className="response-white">{response}</span>}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default AboutProperty
